import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { isLoggedIn } from '../../../utils/auth';


export default function Nav() {
  const loggedIn = isLoggedIn();
  return (
    <Popover className="relative bg-white">
      <div className="absolute inset-0 shadow z-30 pointer-events-none" aria-hidden="true" />
      <div className="relative z-20">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
          <div className="-my-3">
            <Link to="/" className="flex">
              <span className="sr-only">Fun Bowling Leagues</span>
              <img
                className="w-auto h-20"
                src="/fbl-logo-real.png"
                alt=""
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <nav className="flex space-x-10">
              <Link to="/bowling-centers" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Bowling Centers
              </Link>
              <Link to="/leagues" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Leagues
              </Link>

              <Link to="/contact" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Contact Us
              </Link>
            </nav>
            <div className="flex items-center md:ml-12">
              {!loggedIn && (
                <>
                  <Link to="/sign-in" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Sign in
                  </Link>
                  <Link
                    to="/bowling-centers"
                    className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Sign up
                  </Link>
                </>
              )}
              {loggedIn && (
                <>
                  <Link to="/app/dashboard" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Dashboard
                  </Link>
                  <Link
                    to="/sign-out"
                    className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Sign out
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">

          <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Fun Bowling Leagues"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            
            <div className="py-6 px-5">
              <div className="grid grid-cols-1 gap-4">
                <Link to="/bowling-centers" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                  Bowling Centers
                </Link>
                <Link to="/leagues" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                  Leagues
                </Link>
              </div>
              <div className="mt-6">
                {!loggedIn && (
                  <>
                    <Link
                      to="/bowling-centers"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Sign up
                    </Link>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Have an account?{' '}
                      <Link to="/sign-in" className="text-indigo-600 hover:text-indigo-500">
                        Sign in
                      </Link>
                    </p>
                  </>
                )}
                {loggedIn && (
                  <>
                    <Link
                      to="/app/dashboard"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Dashboard
                    </Link>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      <Link to="/sign-out" className="text-indigo-600 hover:text-indigo-500">
                        Sign out
                      </Link>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}