import React from 'react';
import Nav from './nav';
import Footer from './footer';
import Layout from '../layout';

export default function Container({ children, hideNav, hideFooter }) {
  return (
    <Layout>
      {!hideNav && <Nav />}
      {children}
      {!hideFooter && <Footer />}
    </Layout>
  );
}
